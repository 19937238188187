import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Popover } from 'react-bootstrap';
import useEntitlements from '@/entitlements/hooks/useEntitlements';
import PointerEventsWrapper from '@/storychief/components/PointerEventsWrapper';
import EntitlementTeaserPanel from '@/entitlements/components/EntitlementTeaserPanel';

const propTypes = {
  children: PropTypes.node.isRequired,
  entitlement: PropTypes.string.isRequired,
  entitlementTitle: PropTypes.node,
  entitlementDescription: PropTypes.node,
  customEntitlementCheck: PropTypes.func,
  tooltipPlacement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  showButton: PropTypes.bool,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
};
const defaultProps = {
  entitlementTitle: null,
  entitlementDescription: null,
  customEntitlementCheck: null,
  tooltipPlacement: 'bottom',
  showButton: true,
  onShow: () => {},
  onHide: () => {},
};

function EntitlementPopover({
  children,
  entitlement,
  customEntitlementCheck,
  entitlementTitle,
  entitlementDescription,
  tooltipPlacement,
  showButton,
  onShow,
  onHide,
}) {
  const getEntitlement = useEntitlements();
  const [show, setShow] = useState(false);
  const containerRef = useRef(null);

  function isEntitled() {
    if (customEntitlementCheck) {
      return customEntitlementCheck();
    }

    return !!getEntitlement(entitlement);
  }

  function onMouseEnter() {
    if (!isEntitled()) {
      setShow(true);
      onShow();
    }
  }

  function onMouseLeave() {
    setShow(false);
    onHide();
  }

  function handleOnUpgrade() {
    setShow(false);
    onHide();
  }

  // Render
  if (isEntitled()) {
    return children;
  }

  return (
    <>
      <div
        className="d-inline-block"
        ref={containerRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <PointerEventsWrapper className="d-inline-block">{children}</PointerEventsWrapper>
      </div>

      <Overlay
        onHide={() => {}}
        target={containerRef.current}
        show={show}
        placement={tooltipPlacement}
      >
        <Popover
          className="popover--modern"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          id="popover-entitlement"
        >
          <EntitlementTeaserPanel
            entitlement={entitlement}
            title={entitlementTitle}
            onUpgrade={handleOnUpgrade}
            showButton={showButton}
          >
            {entitlementDescription}
          </EntitlementTeaserPanel>
        </Popover>
      </Overlay>
    </>
  );
}

EntitlementPopover.propTypes = propTypes;
EntitlementPopover.defaultProps = defaultProps;

export default EntitlementPopover;
