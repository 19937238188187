import { JSX } from 'react';
import useModal from '@/modals/hooks/useModal';
import useUpgradePathForEntitlement from '@/pricing/hooks/useUpgradePathForEntitlement';
import Button from '@/storychief/components/Button';
import StoryChief from '@/storychief';
import analyticsTrack from '@/storychief/utils/analyticsTrack';
import omitGraphQlProps from '@/storychief/utils/omitGraphQlProps';

type UpgradeButtonReturnType = {
  entitlement: string;
  onUpgrade?: () => void;
};

function UpgradeButton({
  entitlement,
  onUpgrade = () => {},
}: UpgradeButtonReturnType): JSX.Element {
  const {
    data: entitlementUpgradeData,
    loading,
    error,
  } = useUpgradePathForEntitlement(entitlement);
  const checkoutModal = useModal('CheckoutModal');

  function handleOnUpgrade() {
    onUpgrade();
    analyticsTrack('Upgrade Intent', { entitlement, upgradeIntentType: 'self-service' });

    checkoutModal.show({
      props: {
        order: omitGraphQlProps(entitlementUpgradeData.upgradePathForEntitlement.payload),
        isUpgrade: true,
        upgradingExistingStripePrice:
          entitlementUpgradeData.upgradePathForEntitlement.prices[0]?.stripe_price,
      },
    });
  }

  function handleOnContactUs() {
    analyticsTrack('Upgrade Intent', { entitlement, upgradeIntentType: 'contact' });

    StoryChief.startIntercomMessage();
  }

  if (error) {
    return (
      <Button variant="secondary" size="xs" onClick={handleOnContactUs}>
        Contact us
      </Button>
    );
  }

  return (
    <Button disabled={loading} variant="secondary" size="xs" onClick={handleOnUpgrade}>
      Upgrade
    </Button>
  );
}

export default UpgradeButton;
